import api from "@/api";

const getters = {
    catalog: (state) => state.catalog,
    catalogFilters: (state) => state.filters,
    newItems: (state) => state.newItems,
    popularItems: (state) => state.popularItems,
};

const state = {
    catalog: [
        // {
        //     id: 1,
        //     title: "Название чего нибудь",
        //     description:
        //         "Lorem ipsum dolor sit amet, consectetur adipisicing elit.\n" +
        //         "                    Architecto at consectetur culpa ducimus eum fuga fugiat,\n" +
        //         "                    ipsa iusto, modi nostrum recusandae reiciendis saepe",
        //     media: [
        //         {
        //             id: 1,
        //             type: "image",
        //             src: "https://placeimg.com/140/180/animals",
        //         },
        //         {
        //             id: 2,
        //             type: "image",
        //             src: "https://placeimg.com/640/480/tech",
        //         },
        //         {
        //             id: 3,
        //             type: "image",
        //             src: "https://placeimg.com/640/480/any",
        //         },
        //     ],
        // },
    ],
    filters: null,
    newItems: null,
    popularItems: null,
};

export const SET_CATALOG = "setCatalog";
export const SET_CATALOG_FILTERS = "setCatalogFilters";
export const SET_NEW_ITEMS = "setNewItems";
export const SET_POPULAR_ITEMS = "setPopularItems";

const mutations = {
    [SET_CATALOG](state, payload) {
        state.catalog = payload;
    },
    [SET_CATALOG_FILTERS](state, payload) {
        state.filters = payload;
    },
    [SET_NEW_ITEMS](state, payload) {
        state.newItems = payload;
    },
    [SET_POPULAR_ITEMS](state, payload) {
        state.popularItems = payload;
    },
};

export const GET_CATALOG = "getCatalog";
export const GET_CATALOG_FILTERS = "getCatalogFilters";
export const GET_NEW_ITEMS = "getNewItems";
export const GET_POPULAR_ITEMS = "getPopularItems";

const actions = {
    [GET_CATALOG](context, payload) {
        return new Promise((resolve, reject) => {
            api.post("/catalog.list", payload)
                .then((r) => {
                    context.commit(SET_CATALOG, r.data);
                    resolve(r.data);
                })
                .catch((r) => {
                    reject(r);
                });
        });
    },
    [GET_CATALOG_FILTERS](context) {
        return new Promise((resolve, reject) => {
            api.post("/catalog.filters")
                .then((r) => {
                    context.commit(SET_CATALOG_FILTERS, r.data);
                    resolve(r.data);
                })
                .catch((r) => {
                    reject(r);
                });
        });
    },
    [GET_NEW_ITEMS](context) {
        return new Promise((resolve, reject) => {
            api.post("/catalog.newItems")
                .then((r) => {
                    context.commit(SET_NEW_ITEMS, r.data);
                    resolve(r.data);
                })
                .catch((r) => {
                    reject(r);
                });
        });
    },
    [GET_POPULAR_ITEMS](context) {
        return new Promise((resolve, reject) => {
            api.post("/catalog.popularItems")
                .then((r) => {
                    context.commit(SET_POPULAR_ITEMS, r.data);
                    resolve(r.data);
                })
                .catch((r) => {
                    reject(r);
                });
        });
    },
};

export default {
    getters,
    state,
    mutations,
    actions,
};
