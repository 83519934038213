<template>
    <div
        class="collection-card"
        :class="{ 'mb-3': !stripped }"
        @click="openCollection()"
    >
        <div>
            <hover-image-slider
                :images="collection.media"
                :stripped="stripped"
            />
            <div class="stripped-text" v-if="stripped">
                {{ collection.title }}
            </div>
        </div>
        <div class="card-body" v-if="!stripped">
            <h3 class="card-title">{{ collection.title }}</h3>
            <p class="text-muted">
                {{ collection.description }}
            </p>
        </div>
    </div>
</template>

<script>
import HoverImageSlider from "@/components/ItemCard/Components/HoverImageSlider";
export default {
    name: "CollectionAlterCard",
    components: { HoverImageSlider },
    props: {
        collection: {
            required: true,
        },
        stripped: {
            default: false,
        },
    },
    // computed: {
    //     collectionImage() {
    //         return this.collection.media.find((v) => {
    //             return v.type === "image";
    //         });
    //     },
    // },
    methods: {
        openCollection() {
            this.$router.push({
                name: "collection",
                params: { id: this.collection.id },
            });
        },
    },
};
</script>

<style scoped lang="scss">
.collection-card {
    width: 100%;
    @media (min-width: 768px) {
        max-width: 300px;
    }
    transition: 0.2s;
    border: none;
    border-radius: 5px;
    overflow: hidden;
    .stripped-text {
        position: absolute;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.4);
        color: #fff;
        padding: 5px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.2s;
        top: calc(30%);
        bottom: calc(30%);
    }
    &:hover {
        cursor: pointer;
        transform: scale(1.03);
        box-shadow: 0 2px 22px -15px black;
        .stripped-text {
            background: rgba(0, 0, 0, 0.6);
            top: 0;
            bottom: 0;
            font-weight: 600;
            font-size: 20px;
        }
    }
    .card-title {
        text-align: center;
        font-size: 20px;
        text-shadow: 2px 2px #e8e8e8;
    }
}
//.collection-card {
//    margin-bottom: 15px;
//    border-radius: 7px;
//    border: none;
//    .card-body {
//        width: 100%;
//        height: 350px;
//        background-position: center;
//        background-repeat: no-repeat;
//        background-size: cover;
//        border-radius: 7px;
//        //box-shadow: 0 0 15px -10px black;
//        //position: relative;
//        //z-index: 1;
//
//        display: flex;
//        align-items: start;
//        justify-content: end;
//        flex-direction: column;
//
//        &:hover {
//            //z-index: 100;
//            //transform: scale(1.1);
//            //cursor: pointer;
//        }
//
//        .card-title {
//            color: #fff;
//            text-shadow: 2px 2px #2a2a2a;
//            margin-bottom: 5px;
//        }
//        p {
//            color: #dedede;
//            margin-bottom: 0;
//            text-shadow: 2px 2px #2a2a2a;
//        }
//    }
//
//    transition: 0.2s;
//    cursor: pointer;
//
//    &:hover {
//        //z-index: 101;
//        transform: scale(1.03);
//        box-shadow: 0 0 25px -7px black;
//        //cursor: pointer;
//
//        .collection-more {
//            opacity: 1;
//        }
//    }
//
//    .collection-more {
//        //margin: 15px;
//        opacity: 0;
//        transition: 0.2s;
//    }
//}
</style>
