import api from "@/api";

const getters = {};

const state = {};

const mutations = {};

export const MAKE_ORDER = "makeOrder";

const actions = {
    [MAKE_ORDER](context, payload) {
        return new Promise((resolve, reject) => {
            api.post("/order.make", payload)
                .then((r) => {
                    resolve(r.data);
                })
                .catch((r) => {
                    reject(r);
                });
        });
    },
};

export default {
    getters,
    state,
    mutations,
    actions,
};
