<template>
    <!--    <footer class="footer d-print-none">
        <div class="container-xl">
            <div class="row text-center align-items-center flex-row-reverse">
                <div class="col-lg-auto ms-lg-auto">
                    <ul class="list-inline list-inline-dots mb-0">
                        <li class="list-inline-item">
                            <router-link
                                :to="{ name: 'catalog' }"
                                class="link-secondary"
                                >Каталог</router-link
                            >
                        </li>
                        <li class="list-inline-item">
                            <router-link
                                :to="{ name: 'delivery' }"
                                class="link-secondary"
                                >Доставка</router-link
                            >
                        </li>
                    </ul>
                </div>
                <div class="col-12 col-lg-auto mt-3 mt-lg-0">
                    <ul class="list-inline list-inline-dots mb-0">
                        <li class="list-inline-item">
                            © 2022
                            <a href="/" class="link-secondary">Shop</a>.
                        </li>
                        <li class="list-inline-item">STARK</li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>-->

    <footer class="footer d-print-none pt-4 pt-md-5 pb-4 mt-5">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md">
                    <a
                        class="navbar-brand font-weight-bold text-black-50"
                        href="#"
                    >
                        SHOK<span class="text-dribbble">BUKET</span>
                    </a>
                    <div class="mb-4">
                        <div>
                            <small>
                                Телефон:
                                <a href="tel:+75555555555">
                                    +7 555 555 55 55
                                </a>
                            </small>
                        </div>
                        <div>
                            <small class="d-block text-muted"
                                >город Киров, улица Молодой Гвардии 90г/1</small
                            >
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md d-none d-md-block"></div>
                <div class="col-6 col-md">
                    <h5>Ссылки</h5>
                    <ul class="list-unstyled text-small">
                        <li>
                            <a class="text-muted" href="#">ссылка</a>
                        </li>
                    </ul>
                </div>
                <div class="col-6 col-md">
                    <h5>Соц. сети</h5>
                    <ul class="list-unstyled text-small social-list">
                        <li>
                            <a
                                class="text-muted"
                                target="_blank"
                                href="https://vk.com/"
                                ><i class="fab fa-vk mr-1"></i>Вконтакте</a
                            >
                        </li>
                        <li>
                            <a
                                class="text-muted"
                                target="_blank"
                                href="https://www.instagram.com/"
                                ><i class="fab fa-instagram mr-1"></i
                                >Instagram</a
                            >
                        </li>
                        <li>
                            <a
                                class="text-muted"
                                target="_blank"
                                href="https://ok.ru/group/"
                                ><i class="fab fa-odnoklassniki mr-1"></i
                                >Одноклассники</a
                            >
                        </li>
                        <li>
                            <a
                                class="text-muted"
                                target="_blank"
                                href="https://youtube.com/channel/"
                                ><i class="fab fa-youtube mr-1"></i>YouTube</a
                            >
                        </li>
                        <li>
                            <a
                                class="text-muted"
                                target="_blank"
                                href="https://www.facebook.com/"
                                ><i class="fab fa-facebook mr-1"></i>Facebook</a
                            >
                        </li>
                    </ul>
                </div>
            </div>
            <div
                class="d-flex flex-column mt-2 justify-content-start align-items-center"
                style="width: fit-content"
            >
                <div class="copyright-text">Разработка</div>
                <a
                    href="https://starkagency.ru/"
                    target="_blank"
                    class="copyright"
                    >Stark</a
                >
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "FooterComponent",
};
</script>

<style lang="scss" scoped>
.copyright-icon,
.copyright {
    font-family: Teko, sans-serif;
}
.copyright-text {
    color: #c5c5c5;
    font-size: 10px;
}
.copyright-icon {
    color: rgba(72, 45, 45, 0.29);
    font-size: 19px;
    margin-right: 6px;
}
.copyright {
    color: #9d9d9d;
    font-size: 30px;
    line-height: 24px;
    opacity: 0.6;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
        opacity: 0.8;
    }
}
.social-list {
    li {
        margin-bottom: 4px;
    }
    i {
        min-width: 22px;
        text-align: center;
    }
}
</style>
