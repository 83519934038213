<template>
    <div class="bg-white">
        <div class="container-xl py-5">
            <div class="block-title text-center pb-4">
                Подборки на все случаи
            </div>
            <div class="row justify-content-center">
                <div
                    class="col-auto"
                    v-for="collection in collections"
                    :key="'collection_' + collection.id"
                >
                    <collection-alter-card :collection="collection" />
                </div>
            </div>
            <div class="d-flex justify-content-center pt-4">
                <router-link
                    :to="{ name: 'collections' }"
                    class="btn btn-dribbble"
                    >Показать больше</router-link
                >
            </div>
        </div>
    </div>
</template>

<script>
import CollectionAlterCard from "@/components/CollectionAlterCard";
export default {
    name: "CollectionsBlock",
    components: { CollectionAlterCard },
    computed: {
        collections() {
            return this.shuffleFisherYates(
                this.$store.getters.collections
            ).slice(0, 4);
        },
    },
    methods: {
        shuffleFisherYates(array) {
            let i = array.length;
            while (i--) {
                const ri = Math.floor(Math.random() * i);
                [array[i], array[ri]] = [array[ri], array[i]];
            }
            return array;
        },
    },
};
</script>

<style scoped></style>
