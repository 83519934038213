import { createRouter, createWebHistory } from "vue-router";
import IndexView from "@/views/IndexView";

const routes = [
    {
        path: "/",
        name: "index",
        component: IndexView,
        meta: {
            title: "Главная",
            metaTags: [
                {
                    name: "description",
                    content: "Главная",
                },
                {
                    property: "og:description",
                    content: "Главная",
                },
            ],
        },
    },
    // {
    //     path: "/about",
    //     name: "about",
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () =>
    //         import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    // },
    {
        path: "/catalog",
        name: "catalog",
        component: () =>
            import(/* webpackChunkName: "catalog" */ "../views/CatalogView"),
    },
    {
        path: "/collections",
        name: "collections",
        component: () =>
            import(
                /* webpackChunkName: "collections" */ "../views/CollectionsView"
            ),
    },
    {
        path: "/delivery",
        name: "delivery",
        component: () =>
            import(/* webpackChunkName: "delivery" */ "../views/DeliveryView"),
    },
    {
        path: "/product/:id",
        name: "product",
        component: () =>
            import(/* webpackChunkName: "product" */ "../views/ProductView"),
    },
    {
        path: "/collection/:id",
        name: "collection",
        component: () =>
            import(
                /* webpackChunkName: "collection" */ "../views/CollectionView"
            ),
    },
];

const router = createRouter({
    history: createWebHistory("/"),
    scrollBehavior: function (to, from, savedPosition) {
        /*return new Promise((resolve) => {
            if (to.hash) {
                setTimeout(() => {
                    resolve({ el: to.hash });
                }, 1000);
            } else if (savedPosition) {
                return savedPosition;
            } else {
                return { x: 0, y: 0 };
            }
        });*/
        if (to.hash) {
            setTimeout(() => {
                const element = document.getElementById(
                    to.hash.replace(/#/, "")
                );
                if (element && element.scrollIntoView) {
                    element.scrollIntoView({
                        block: "start",
                        behavior: "smooth",
                    });
                    setTimeout(() => {
                        element.scrollIntoView({
                            block: "start",
                            behavior: "auto",
                        });
                    }, 1000);
                }
            }, 300);

            //NOTE: This doesn't work for Vue 3
            //return {selector: to.hash}

            //This does
            return { el: to.hash };
        } else if (savedPosition) {
            return savedPosition;
        }
        return { top: 0 };
    },
    routes,
});

router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.metaTags);

    const previousNearestWithMeta = from.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    } else if (previousNearestWithMeta) {
        document.title = previousNearestWithMeta.meta.title;
    }

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
        (el) => el.parentNode.removeChild(el)
    );

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags
        .map((tagDef) => {
            const tag = document.createElement("meta");

            Object.keys(tagDef).forEach((key) => {
                tag.setAttribute(key, tagDef[key]);
            });

            // We use this to track which meta tags we create so we don't interfere with other ones.
            tag.setAttribute("data-vue-router-controlled", "");

            return tag;
        })
        // Add the meta tags to the document head.
        .forEach((tag) => document.head.appendChild(tag));

    next();
});

export default router;
