<template>
    <div class="container-xl py-5">
        <div class="block-title text-center pb-4">Что такое ShokBuket</div>
        <div>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab ad
            aperiam at debitis distinctio dolore error et excepturi facilis
            harum hic in incidunt ipsum itaque nam natus nulla officia omnis
            optio, praesentium provident quasi quidem, rerum sit soluta ut vel
            voluptate? Accusamus aliquid blanditiis dolore enim error et, harum
            itaque laborum nemo perferendis quidem, quisquam quos repudiandae
            velit veniam? Asperiores commodi dicta dolore, dolorem doloribus
            ducimus et eum fuga ipsum labore molestiae nemo neque nisi, nulla
            quis quisquam reiciendis rem repudiandae similique sit veniam
            voluptatem voluptatum? A deserunt doloremque eius esse, facilis
            inventore ipsa, ipsum maxime minima nam nisi numquam omnis provident
            quae quam sunt ut. Aperiam architecto atque autem beatae consectetur
            consequuntur cumque delectus doloremque, earum eius eligendi ex
            expedita fuga incidunt inventore iure labore magni maiores nesciunt
            numquam praesentium, quae quisquam repellendus saepe sint suscipit
            tenetur voluptates. A ab aperiam asperiores assumenda consequatur
            culpa dicta dignissimos dolore, ducimus earum eius eos error eum
            eveniet incidunt inventore ipsa magnam maiores mollitia
            necessitatibus nemo nesciunt nisi officiis omnis quae quaerat quam
            similique ut veritatis, voluptate. Ab, at commodi, consequuntur
            dolor ducimus eveniet exercitationem inventore labore nisi porro
            quas repellat similique soluta unde ut velit voluptate voluptatum?
            Animi architecto ducimus ea eligendi impedit magni, molestiae neque
            nisi officia perferendis praesentium quaerat qui quibusdam sunt
            voluptate? Ab accusantium autem deleniti dolores earum eius minus
            molestias nisi nulla quae quidem ratione reprehenderit saepe sequi,
            veniam vero voluptatibus. Cum dignissimos fugiat, harum maiores
            mollitia quam quasi quia ratione reprehenderit sequi tempora totam,
            vel vero. Accusamus atque delectus distinctio dolore, eligendi est
            eum impedit maxime porro possimus quas quibusdam quidem quis quo,
            sed suscipit vel voluptas voluptatibus. Accusamus at harum minus
            modi, non optio praesentium quasi reiciendis sunt tenetur velit
            veniam, voluptas voluptatem! Alias dicta est exercitationem facere
            facilis fuga laboriosam libero! Accusantium dolore in minus
            necessitatibus soluta tempora tempore tenetur ut veniam. Accusamus
            eaque excepturi fugit itaque quidem, rem. Animi ducimus error
            exercitationem odio rem. Consequatur culpa debitis deserunt dolor
            eaque eius error inventore ipsam laboriosam non nostrum perferendis,
            porro quam repellat veniam. Accusamus aliquam deleniti dolore
            dolorum eligendi enim est ex harum, ipsam, molestias nulla officia
            quaerat sequi vitae voluptatibus. A alias aliquid doloremque dolorum
            esse eveniet, fugit laborum non perspiciatis provident quam quasi
            similique sunt suscipit velit veniam vitae voluptatibus? Distinctio
            hic natus quas. Aut eaque est facilis fugiat illo mollitia
            necessitatibus obcaecati quae quasi quis tempora, unde velit?
            Debitis deleniti eius ipsam magnam maiores modi mollitia nemo rem
            vitae. Ab ad aliquid aut autem, distinctio dolorem dolores eligendi
            error est in itaque magni modi, natus, necessitatibus perferendis
            ratione repudiandae sint sunt tempore totam vel voluptatibus
            voluptatum? Ad autem, cum doloremque eligendi fuga fugit ipsam iure
            minima non obcaecati odio pariatur perferendis quis repellat
            repudiandae soluta sunt vel veritatis vero voluptatem. Amet
            assumenda corporis culpa delectus dolorem dolorum eligendi,
            excepturi itaque, modi natus obcaecati omnis quas vero. Assumenda
            commodi earum illo omnis perspiciatis quam qui quia ut? Beatae
            commodi distinctio exercitationem laboriosam, perspiciatis soluta
            suscipit! Aspernatur dolorem eum maiores quaerat quibusdam repellat!
        </div>
    </div>
</template>

<script>
export default {
    name: "AboutBlock",
};
</script>

<style scoped></style>
