<template>
    <header-component />
    <router-view />
    <footer-component />
</template>
<script>
import FooterComponent from "@/components/FooterComponent";
import HeaderComponent from "@/components/HeaderComponent";
export default {
    components: { HeaderComponent, FooterComponent },
};
</script>
