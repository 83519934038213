<template>
    <div class="py-5">
        <div class="block-title text-center pb-4">Отзывы клиентов</div>
        <Splide
            v-if="loaded"
            aria-labelledby="thumbnail-example-heading"
            :options="mainOptions"
            ref="main"
        >
            <SplideSlide class="pt-4" v-for="slide in reviews" :key="slide.id">
                <div class="review-box">
                    <div class="review-avatar mb-3">
                        <img :src="slide.image" alt="" />
                    </div>
                    <div class="review-bg">
                        <div class="review-text">{{ slide.text }}</div>
                        <div class="review-name">
                            <span></span> {{ slide.name }}
                        </div>
                    </div>
                    <div class="review-link">
                        <a
                            :href="
                                'https://vk.com/topic-185541623_40555047?post=' +
                                slide.vk_id
                            "
                            target="_blank"
                            class="text-vk"
                            >Читать отзыв<i class="fab fa-vk ms-1"></i
                        ></a>
                    </div>
                </div>
            </SplideSlide>
        </Splide>
        <div class="d-flex justify-content-center pt-4">
            <a
                href="https://vk.com/shokbuket43"
                target="_blank"
                class="btn btn-light"
                >Больше отзывов в сообществе
                <span class="text-vk"
                    ><i class="fab fa-vk mx-1 pt-1"></i>Вконтакте</span
                ></a
            >
        </div>
    </div>
</template>

<script>
import { SplideSlide, Splide } from "@splidejs/vue-splide";
export default {
    name: "ReviewBlock",
    components: { Splide, SplideSlide },
    computed: {
        reviews() {
            return this.$store.getters.reviews;
        },
    },
    data: () => {
        return {
            loaded: true,
            mainOptions: {
                type: "loop",
                perPage: 4,
                perMove: 1,
                gap: "1rem",
                padding: { left: "15%", right: "15%" },
                pagination: false,
                autoplay: true,
            },
            // reviews: [
            //     {
            //         id: 1,
            //         img: null,
            //         name: "ivan",
            //         text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi consequuntur debitis doloribus excepturi iure laboriosam nihil numquam quibusdam quisquam tempore.",
            //         link: "vk",
            //     },
            //     {
            //         id: 2,
            //         img: null,
            //         name: "neivan",
            //         text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eveniet iusto, nobis ratione saepe sapiente sed soluta veritatis? Atque debitis eius iusto laudantium minus quaerat quibusdam sit sunt! Aperiam consequatur corporis dolorum ducimus eligendi excepturi, necessitatibus pariatur porro provident voluptatem! Est ex minima repellat rerum vitae! Id quod quos recusandae sapiente?",
            //         link: "vk",
            //     },
            // ],
        };
    },
    mounted() {
        if (window.innerWidth < 768) {
            this.mainOptions.perPage = 1;
        } else if (window.innerWidth < 990) {
            this.mainOptions.perPage = 2;
        } else if (window.innerWidth < 1400) {
            this.mainOptions.perPage = 3;
        }
    },
};
</script>

<style lang="scss" scoped>
.review-box {
    .review-avatar {
        text-align: center;
        img {
            width: 150px;
            border-radius: 15px;
        }
    }
    .review-name {
        margin-top: 15px;
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 10px;
        color: #959595;
        display: flex;
        align-items: center;
        span {
            width: 20px;
            height: 1px;
            background: #c2c2c2;
            display: inline-block;
            margin-right: 8px;
        }
    }
    .review-bg {
        background: #fff;
        padding: 15px 20px;
        border-radius: 15px;
        min-height: 250px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
    .review-text {
        font-style: italic;
    }
    .review-link {
        margin-top: 10px;
        text-align: center;
        a {
            //text-decoration: underline;
        }
    }
}
</style>
