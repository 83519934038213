<template>
    <div class="start-block-background d-flex align-items-center">
        <div class="container-xl">
            <div class="row align-items-center">
                <div class="col-12 col-md-7">
                    <div class="start-text">
                        <h1 class="display-3">
                            <span class="font-weight-bold text-black-50">
                                SHOK<span class="text-dribbble">BUKET</span>
                            </span>
                        </h1>
                        <h2>Шоколадные сладости</h2>
                        <p class="mb-5">
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit. A adipisci architecto beatae consectetur
                            debitis doloribus dolorum, earum eveniet expedita id
                            inventore minus modi nemo neque provident similique
                            vel vitae voluptatem! Animi assumenda blanditiis
                            cupiditate nam necessitatibus nisi nostrum odio
                            placeat provident quibusdam, quidem quis quisquam
                            tempora totam velit? Delectus, unde!
                        </p>
                        <router-link
                            :to="{ name: 'catalog' }"
                            class="btn btn-dribbble btn-lg"
                        >
                            Открыть каталог
                            <span class="d-none d-sm-inline-block">
                                &nbsp;вкусняшек
                            </span>
                        </router-link>
                    </div>
                </div>
                <div class="col-12 col-md-auto d-none" v-if="newItems">
                    <div class="py-1 px-md-5">
                        <div
                            v-for="slide in newItems"
                            :key="'start_' + slide.id"
                        >
                            <item-card :item="slide" />
                        </div>
                    </div>
                </div>
                <!--                <div class="col-12 col-md-auto" v-if="newItems">
                    <Splide
                        aria-labelledby="thumbnail-example-heading"
                        :options="mainOptions"
                        class="py-1 px-md-5"
                    >
                        <SplideSlide
                            v-for="slide in newItems"
                            :key="'start_' + slide.id"
                            class="p-1"
                        >
                            <item-card :item="slide" />
                        </SplideSlide>
                    </Splide>
                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
import ItemCard from "@/components/ItemCard";
// import { Splide, SplideSlide } from "@splidejs/vue-splide";
import { GET_NEW_ITEMS } from "@/store/modules/catalog";
export default {
    name: "StartBlock",
    components: { ItemCard },

    data() {
        return {
            // newItems: [],
            mainOptions: {
                type: "slide",
                perPage: window.innerWidth < 768 ? 1 : 1,
                perMove: 1,
                gap: "1rem",
                pagination: false,
                autoplay: true,
            },
        };
    },
    computed: {
        newItems() {
            return this.$store.getters.newItems;
        },
    },
    mounted() {
        this.$store.dispatch(GET_NEW_ITEMS);
    },
};
</script>

<style scoped lang="scss">
.start-block-background {
    min-height: 800px;
    //background-image: url("https://media.cooky.vn/images/blog-2016/thuc-pham-lam-mo-mat-duc-thuy-tinh-the%202.jpg");
    //background-image: url("https://img5.goodfon.ru/original/2880x1800/d/a4/vypechka-keksy-krem-ukrashenie-desert-iagody-vishnia-chernik.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        margin-top: -40px;
        width: 100%;
        height: 100vh;
        background: white;
        clip-path: polygon(0 0, 100% 0, 100% 60%, 0% 80%);
        z-index: -1;

        @media (max-width: 768px) {
            clip-path: polygon(0 0, 100% 0, 100% 70%, 0% 80%);
        }
    }

    @media (max-width: 768px) {
        min-height: 500px;
    }

    @media (min-width: 768px) {
        background-image: url("@/assets/bg.png");
    }
}

.start-text {
    //background: rgb(255 255 255 / 65%);
    border-radius: 15px;
    color: #000;
    font-size: 16px;
    font-weight: 600;
    line-height: 25px;
    //background: #f5f7fb;
    @media (min-width: 768px) {
        padding: 50px 60px;
    }

    h1 {
        background: linear-gradient(60deg, orange, cyan);
        background-clip: text;
        color: transparent;

        animation: changeBg 3s ease-in-out infinite alternate;

        @media (max-width: 380px) {
            font-size: 16vw;
        }
    }
}

@keyframes changeBg {
    0% {
        filter: hue-rotate(0deg);
    }
    100% {
        filter: hue-rotate(360deg);
    }
}
</style>
