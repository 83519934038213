<template>
    <div>
        <start-block />
        <recommend-block />
        <about-block />
        <collections-block />
        <review-block />
        <consultation-block />
    </div>
</template>

<script>
import StartBlock from "@/views/IndexView/Components/StartBlock";
import RecommendBlock from "@/views/IndexView/Components/RecommendItemsBlock";
import CollectionsBlock from "@/views/IndexView/Components/CollectionsBlock";
import ConsultationBlock from "@/views/IndexView/Components/ConsultationBlock";
import ReviewBlock from "@/views/IndexView/Components/ReviewBlock";
import AboutBlock from "@/views/IndexView/Components/AboutBlock";
export default {
    name: "IndexView",
    components: {
        AboutBlock,
        ReviewBlock,
        ConsultationBlock,
        CollectionsBlock,
        RecommendBlock,
        StartBlock,
    },
};
</script>

<style scoped></style>
