import api from "@/api";

// const inBrowser = typeof window !== "undefined" && window !== null;
// const webp = function () {
//     if (!inBrowser) return false;
//
//     let support = true;
//
//     try {
//         const elem = document.createElement("canvas");
//
//         if (elem.getContext && elem.getContext("2d")) {
//             support =
//                 elem.toDataURL("image/webp").indexOf("data:image/webp") === 0;
//         }
//     } catch (err) {
//         support = false;
//     }
//
//     return support ? ".webp" : "";
// };

const getters = {
    items: (state) => state.items,
    // modal: (state) => state.modal,
    // cart: (state) => {
    //     return state.items
    //         .filter((v) => v.active)
    //         .map((v) => {
    //             return { id: v.id, count: v.count };
    //         });
    // },
    // items: (state) => state.items,
    // orderPrice: (state) => {
    //     return state.items
    //         .filter((v) => v.active)
    //         .reduce((pv, v) => {
    //             return v.gift ? pv : pv + v.price * v.count;
    //         }, 0);
    // },
    // orderId: (state) => state.order_id,
};

const state = {
    // date: `${
    //     new Date().getDate() > 9
    //         ? new Date().getDate()
    //         : `0${new Date().getDate()}`
    // }.${
    //     new Date().getMonth() > 8
    //         ? new Date().getMonth() + 1
    //         : `0${new Date().getMonth() + 1}`
    // }`,
    // modal: false,
    // cart: [],
    // order_id: null,
    items: [
        {
            id: 1,
            title: "Название чего нибудь",
            description: "Описание",
            group: {
                id: 1,
                name: "Популярное",
            },
            attributes: [
                {
                    id: 1,
                    title: "Сахар",
                    description: "100 г",
                },
                {
                    id: 2,
                    title: "Вес",
                    description: "1 кг",
                },
            ],
            media: [
                {
                    id: 1,
                    type: "image",
                    src: "https://placeimg.com/140/180/animals",
                },
                {
                    id: 2,
                    type: "image",
                    src: "https://placeimg.com/640/480/tech",
                },
                {
                    id: 3,
                    type: "image",
                    src: "https://placeimg.com/640/480/any",
                },
            ],
            tags: [
                {
                    id: 1,
                    name: "Домашнее",
                },
                {
                    id: 2,
                    name: "Шоколад",
                },
            ],
            collections: [
                {
                    id: 1,
                    name: "Другу",
                },
                {
                    id: 2,
                    name: "Брату",
                },
            ],
        },
        {
            id: 2,
            title: "Название чего нибудь2",
            description: "Описание2",
            group: {
                id: 1,
                name: "Популярное",
            },
            attributes: [
                {
                    id: 1,
                    title: "Сахар",
                    description: "200 г",
                },
                {
                    id: 2,
                    title: "Вес",
                    description: "1 кг",
                },
            ],
            media: [
                {
                    id: 1,
                    type: "image",
                    src: "https://placeimg.com/1040/1480/animals",
                },
                {
                    id: 2,
                    type: "image",
                    src: "https://placeimg.com/640/480/tech",
                },
                {
                    id: 3,
                    type: "image",
                    src: "https://placeimg.com/640/480/any",
                },
            ],
            tags: [
                {
                    id: 1,
                    name: "Домашнее",
                },
            ],
            collections: [
                {
                    id: 1,
                    name: "Сестре",
                },
                {
                    id: 2,
                    name: "Брату",
                },
            ],
        },
        {
            id: 3,
            title: "Название чего нибудь2",
            description: "Описание2",
            group: {
                id: 1,
                name: "Популярное",
            },
            attributes: [
                {
                    id: 1,
                    title: "Сахар",
                    description: "200 г",
                },
                {
                    id: 2,
                    title: "Вес",
                    description: "1 кг",
                },
            ],
            media: [
                {
                    id: 1,
                    type: "image",
                    src: "https://placeimg.com/640/480/animals",
                },
                {
                    id: 2,
                    type: "image",
                    src: "https://placeimg.com/640/480/tech",
                },
                {
                    id: 3,
                    type: "image",
                    src: "https://placeimg.com/640/480/any",
                },
            ],
            tags: [
                {
                    id: 1,
                    name: "Домашнее",
                },
            ],
            collections: [
                {
                    id: 1,
                    name: "Сестре",
                },
                {
                    id: 2,
                    name: "Брату",
                },
            ],
        },
        {
            id: 4,
            title: "Название чего нибудь2",
            description: "Описание2",
            group: {
                id: 1,
                name: "Популярное",
            },
            attributes: [
                {
                    id: 1,
                    title: "Сахар",
                    description: "200 г",
                },
                {
                    id: 2,
                    title: "Вес",
                    description: "1 кг",
                },
            ],
            media: [
                {
                    id: 1,
                    type: "image",
                    src: "https://placeimg.com/640/480/animals",
                },
                {
                    id: 2,
                    type: "image",
                    src: "https://placeimg.com/640/480/tech",
                },
                {
                    id: 3,
                    type: "image",
                    src: "https://placeimg.com/640/480/any",
                },
            ],
            tags: [
                {
                    id: 1,
                    name: "Домашнее",
                },
            ],
            collections: [
                {
                    id: 1,
                    name: "Сестре",
                },
                {
                    id: 2,
                    name: "Брату",
                },
            ],
        },
        {
            id: 5,
            title: "Название чего нибудь2",
            description: "Описание2",
            group: {
                id: 1,
                name: "Популярное",
            },
            attributes: [
                {
                    id: 1,
                    title: "Сахар",
                    description: "200 г",
                },
                {
                    id: 2,
                    title: "Вес",
                    description: "1 кг",
                },
            ],
            media: [
                {
                    id: 1,
                    type: "image",
                    src: "https://placeimg.com/640/480/animals",
                },
                {
                    id: 2,
                    type: "image",
                    src: "https://placeimg.com/640/480/tech",
                },
                {
                    id: 3,
                    type: "image",
                    src: "https://placeimg.com/640/480/any",
                },
            ],
            tags: [
                {
                    id: 1,
                    name: "Домашнее",
                },
            ],
            collections: [
                {
                    id: 1,
                    name: "Сестре",
                },
                {
                    id: 2,
                    name: "Брату",
                },
            ],
        },
    ],
};

// export const EDIT_MODAL = "editModal";
// export const SET_ORDER_ID = "setOrderId";

const mutations = {
    // [EDIT_MODAL](state, payload) {
    //     state.modal = payload;
    // },
    // [SET_ORDER_ID](state, payload) {
    //     state.order_id = payload;
    // },
};

// export const TOGGLE_ITEM = "toggleItem";
// export const CREATE_ORDER = "createOrder";
// export const CREATE_REVIEW = "createReview";
// export const CREATE_QUESTION = "createQuestion";
// export const CREATE_ORDER_ONE = "createOrderOne";
export const GET_ITEM = "getItem";

const actions = {
    // [TOGGLE_ITEM](context, payload) {
    //     return new Promise((resolve) => {
    //         payload.active = !payload.active;
    //         if (payload.id === 1659) {
    //             if (payload.active) payload.count = 1;
    //             if (!payload.active) payload.count = 0;
    //         }
    //         resolve();
    //     });
    // },
    // [CREATE_ORDER](context) {
    //     return new Promise((resolve, reject) => {
    //         if (context.getters.orderId === null) {
    //             let phone = context.getters.user.phone.replaceAll(
    //                 /[-\s()]/g,
    //                 ""
    //             );
    //             if (phone.length >= 11) {
    //                 api.post("/order.create", {
    //                     user: { ...context.getters.user, phone },
    //                     cart: context.getters.cart,
    //                 })
    //                     .then((r) => {
    //                         context.commit(SET_ORDER_ID, r.data.id);
    //                         resolve(r.data);
    //                     })
    //                     .catch((r) => {
    //                         reject(r);
    //                     });
    //             } else {
    //                 // eslint-disable-next-line prefer-promise-reject-errors
    //                 reject(["phone"]);
    //             }
    //         } else {
    //             resolve({ id: context.getters.orderId });
    //         }
    //     });
    // },
    // [CREATE_ORDER_ONE](context, itemId) {
    //     return new Promise((resolve, reject) => {
    //         let phone = context.getters.user.phone.replaceAll(/[-\s()]/g, "");
    //         if (phone.length >= 11) {
    //             api.post("/order.create", {
    //                 user: { ...context.getters.user, phone },
    //                 cart: [{ count: 1, id: itemId }],
    //             })
    //                 .then((r) => {
    //                     // context.commit(SET_ORDER_ID, r.data.id);
    //                     resolve(r.data);
    //                 })
    //                 .catch((r) => {
    //                     reject(r);
    //                 });
    //         } else {
    //             // eslint-disable-next-line prefer-promise-reject-errors
    //             reject(["phone"]);
    //         }
    //     });
    // },
    // [CREATE_REVIEW](context, reviewText) {
    //     return new Promise((resolve, reject) => {
    //         let phone = context.getters.user.phone.replaceAll(/[-\s()]/g, "");
    //         let errors = [];
    //         if (reviewText.length < 3) errors.push("text");
    //         if (phone.length < 11) errors.push("phone");
    //
    //         if (errors.length === 0) {
    //             api.post("/review.create", {
    //                 user: { ...context.getters.user, phone },
    //                 cart: [{ count: 1, id: 2143 }],
    //                 review: reviewText,
    //             })
    //                 .then((r) => {
    //                     // context.commit(SET_ORDER_ID, r.data.id);
    //                     resolve(r.data);
    //                 })
    //                 .catch((r) => {
    //                     reject(r);
    //                 });
    //         } else {
    //             // eslint-disable-next-line prefer-promise-reject-errors
    //             reject(errors);
    //         }
    //     });
    // },
    // [CREATE_QUESTION](context, questionText) {
    //     return new Promise((resolve, reject) => {
    //         let phone = context.getters.user.phone.replaceAll(/[-\s()]/g, "");
    //         let errors = [];
    //         if (questionText.length < 3) errors.push("text");
    //         if (phone.length < 10) errors.push("phone");
    //
    //         if (errors.length === 0) {
    //             api.post("/question.create", {
    //                 user: { ...context.getters.user, phone },
    //                 cart: [{ count: 1, id: 2143 }],
    //                 question: questionText,
    //             })
    //                 .then((r) => {
    //                     // context.commit(SET_ORDER_ID, r.data.id);
    //                     resolve(r.data);
    //                 })
    //                 .catch((r) => {
    //                     reject(r);
    //                 });
    //         } else {
    //             // eslint-disable-next-line prefer-promise-reject-errors
    //             reject(errors);
    //         }
    //     });
    // },
    [GET_ITEM](context, id) {
        return new Promise((resolve, reject) => {
            api.post("/items.getOne", {
                id,
            })
                .then((r) => {
                    resolve(r.data);
                })
                .catch((r) => {
                    reject(r);
                });
        });
    },
};

export default {
    getters,
    state,
    mutations,
    actions,
};
