import api from "@/api";

const getters = {
    reviews: (state) => state.reviews,
};

const state = {
    reviews: [],
};

export const SET_REVIEWS = "setReviews";

const mutations = {
    [SET_REVIEWS](state, payload) {
        state.reviews = payload;
    },
};

export const GET_REVIEWS = "getReviews";

const actions = {
    [GET_REVIEWS](context) {
        return new Promise((resolve, reject) => {
            api.post("/reviews.last")
                .then((r) => {
                    context.commit(SET_REVIEWS, r.data);
                    resolve(r.data);
                })
                .catch((r) => {
                    reject(r);
                });
        });
    },
};

export default {
    getters,
    state,
    mutations,
    actions,
};
