import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { GET_COLLECTIONS } from "@/store/modules/collections";
import { GET_REVIEWS } from "@/store/modules/reviews";

require("@tabler/core");
require("@tabler/core/dist/css/tabler.min.css");
require("@tabler/core/dist/css/tabler-vendors.min.css");

require("@/styles/main.scss");

// import VueSplide from "@splidejs/vue-splide";
require("@splidejs/vue-splide/css");

const app = createApp(App);

app.use(store).use(router);

// app.use(VueSplide);

store.dispatch(GET_COLLECTIONS);
store.dispatch(GET_REVIEWS);

app.mount("#app");
