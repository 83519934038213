<template>
    <!--    <div class="position-relative product-card" @click="openProduct()">
        <div
            class="product-image"
            :style="{ 'background-image': 'url(' + productImage.src + ')' }"
        >
            <button class="btn btn-success w-100 product-more">
                Подробнее
            </button>
        </div>
    </div>-->
    <!--        <div class="card product-card" @click="openProduct()">
        <div class="ribbon bg-red">Новинка</div>
        <div
            class="card-body"
            :style="{ 'background-image': 'url(' + productImage.src + ')' }"
        >
            <div class="w-100">
                <h3 class="card-title">{{ item.title }}</h3>
                <p>{{ item.description }}</p>
                &lt;!&ndash;                <button class="btn btn-primary w-100 product-more">
                    Подробнее
                </button>&ndash;&gt;
            </div>
        </div>
    </div>-->
    <div class="card product-card mb-3" @click="openProduct()">
        <div class="ribbon bg-red" v-if="item.new">Новинка</div>
        <div class="ribbon bg-primary" v-if="item.popular">Популярно</div>
        <div class="ribbon bg-success" v-if="item.old_price != 0">Скидка</div>
        <div class="ribbon bg-orange" v-if="item.stock > 0 && item.stock < 5">
            Осталось {{ item.stock }} шт.
        </div>
        <div class="ribbon bg-secondary" v-if="item.stock === 0">
            Закончились
        </div>
        <!--        <div
            class="card-img-top img-responsive img-responsive-4x3"
            :style="{ 'background-image': 'url(' + productImage.src + ')' }"
        ></div>-->
        <div>
            <hover-image-slider :images="item.media" />
        </div>
        <div class="card-body">
            <h3 class="card-title">{{ item.title }}</h3>
            <p class="text-muted">
                {{ item.description }}
            </p>
            <!--            <button class="btn btn-primary product-more" @click="openProduct()">
                Подробнее
            </button>-->
        </div>
    </div>
</template>

<script>
import HoverImageSlider from "@/components/ItemCard/Components/HoverImageSlider";

export default {
    name: "ItemCard",
    components: { HoverImageSlider },

    props: {
        item: {
            required: true,
        },
    },
    // computed: {
    //     productImage() {
    //         return this.item.media.find((v) => {
    //             return v.type === "image";
    //         });
    //     },
    // },
    methods: {
        openProduct() {
            this.$router.push({
                name: "product",
                params: { id: this.item.id },
            });
        },
    },
};
</script>

<style scoped lang="scss">
.product-card {
    transition: 0.2s;
    @media (min-width: 768px) {
        max-width: 300px;
    }
    &:hover {
        cursor: pointer;
        transform: scale(1.03);
        box-shadow: 0 2px 22px -15px black;
    }
    .card-title {
        color: #000;
        text-align: center;
        font-size: 20px;
        text-shadow: 2px 2px #e8e8e8;
    }
    border: none;
}
.ribbon {
    &:nth-child(2) {
        margin-top: 40px;
    }
    &:nth-child(3) {
        margin-top: 80px;
    }
    &:nth-child(4) {
        margin-top: 120px;
    }
    &:nth-child(5) {
        margin-top: 160px;
    }
}
//.product-card {
//    margin-bottom: 15px;
//    border-radius: 7px;
//    border: none;
//    .card-body {
//        width: 100%;
//        height: 350px;
//        background-position: center;
//        background-repeat: no-repeat;
//        background-size: cover;
//        border-radius: 7px;
//        //box-shadow: 0 0 15px -10px black;
//        //position: relative;
//        //z-index: 1;
//
//        display: flex;
//        align-items: start;
//        justify-content: end;
//        flex-direction: column;
//
//        &:hover {
//            //z-index: 100;
//            //transform: scale(1.1);
//            //cursor: pointer;
//        }
//
//        .card-title {
//            color: #fff;
//            text-shadow: 2px 2px #2a2a2a;
//            margin-bottom: 5px;
//        }
//        p {
//            color: #dedede;
//            margin-bottom: 0;
//            text-shadow: 2px 2px #2a2a2a;
//        }
//    }
//
//    transition: 0.2s;
//    cursor: pointer;
//
//    &:hover {
//        //z-index: 101;
//        transform: scale(1.03);
//        box-shadow: 0 0 25px -7px black;
//        //cursor: pointer;
//
//        .product-more {
//            opacity: 1;
//        }
//    }
//
//    .product-more {
//        //margin: 15px;
//        opacity: 0;
//        transition: 0.2s;
//    }
//}
</style>
