<template>
    <header
        class="navbar navbar-expand-lg navbar-dark d-print-none bg-dribbble"
    >
        <div class="container-xl">
            <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbar-menu"
            >
                <span class="navbar-toggler-icon"></span>
            </button>
            <h1
                class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3"
            >
                <router-link
                    :to="{ name: 'index' }"
                    class="d-flex align-items-center text-decoration-none"
                >
                    <img
                        src="@/assets/logotype.png"
                        alt="Tabler"
                        class="navbar-brand-image me-3"
                        width="32"
                        height="32"
                    />
                    <span class="font-weight-bold text-white h2 mb-0">
                        SHOK<span class="text-white-50">BUKET</span>
                    </span>
                </router-link>
            </h1>
            <div
                class="collapse navbar-collapse justify-content-end"
                id="navbar-menu"
            >
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a href="tel:000" class="phone nav-link text-white"
                            >+7 555 555 55 55</a
                        >
                    </li>
                    <router-link
                        v-for="item in menu"
                        :to="item.to"
                        :key="'menuItem_' + item.id"
                        v-slot="{ href, navigate, isActive, isExactActive }"
                        custom
                    >
                        <li
                            class="nav-item"
                            :class="[
                                isActive && 'active',
                                isExactActive && 'active',
                            ]"
                        >
                            <a class="nav-link" :href="href" @click="navigate">
                                <!--                                <span
                                    class="nav-link-icon d-md-none d-lg-inline-block"
                                    >&lt;!&ndash; Download SVG icon from http://tabler-icons.io/i/star &ndash;&gt;
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="icon"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        stroke-width="2"
                                        stroke="currentColor"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                        ></path>
                                        <path
                                            d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"
                                        ></path>
                                    </svg>
                                </span>-->
                                <span class="nav-link-title">
                                    {{ item.title }}
                                </span>
                            </a>
                        </li>
                    </router-link>
                </ul>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name: "HeaderComponent",
    data: function () {
        return {
            menu: [
                {
                    id: 1,
                    title: "Главная",
                    to: {
                        name: "index",
                    },
                },
                {
                    id: 2,
                    title: "Каталог продукции",
                    to: {
                        name: "catalog",
                    },
                },
                {
                    id: 3,
                    title: "Тематические подборки",
                    to: {
                        name: "collections",
                    },
                },
                {
                    id: 4,
                    title: "Доставка",
                    to: {
                        name: "delivery",
                    },
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.phone {
    font-weight: 600;
    font-size: 20px;
    color: #fff;
    margin-right: 15px;
    &:hover {
        text-decoration: none;
    }
}
</style>
