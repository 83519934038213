import { createStore } from "vuex";
import item from "@/store/modules/item";
import collections from "@/store/modules/collections";
import catalog from "@/store/modules/catalog";
import order from "@/store/modules/order";
import reviews from "@/store/modules/reviews";

export default createStore({
    modules: {
        item,
        collections,
        catalog,
        order,
        reviews,
    },
});
