<template>
    <div class="container-xl py-5">
        <div class="block-title text-center pb-4">Популярное за месяц</div>
        <div class="row justify-content-center">
            <div
                class="col-auto"
                v-for="item in recommendItems"
                :key="'recommendItem_' + item.id"
            >
                <item-card :item="item" />
            </div>
        </div>
        <div class="d-flex justify-content-center pt-4">
            <router-link :to="{ name: 'catalog' }" class="btn btn-dribbble"
                >Открыть каталог</router-link
            >
        </div>
    </div>
</template>

<script>
import ItemCard from "@/components/ItemCard";
import { GET_POPULAR_ITEMS } from "@/store/modules/catalog";
export default {
    name: "RecommendItemsBlock",
    components: { ItemCard },
    computed: {
        recommendItems() {
            return this.$store.getters.popularItems;
        },
    },
    mounted() {
        this.$store.dispatch(GET_POPULAR_ITEMS);
    },
};
</script>
