<template>
    <div class="container-xl py-5">
        <div class="block-title text-center pb-4">Нужна консультация?</div>
        <div class="row justify-content-center">
            <div class="col-4">
                <div class="pb-2">Укажите свой номер и мы свяжемся с Вами:</div>
                <div class="row">
                    <div class="col">
                        <input
                            type="tel"
                            class="form-control"
                            placeholder="8 900 123 45 67"
                        />
                    </div>
                    <div class="col-auto">
                        <button class="btn btn-dribbble">Отправить</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center pt-4"></div>
    </div>
</template>

<script>
export default {
    name: "ConsultationBlock",
};
</script>

<style scoped></style>
