import api from "@/api";

const getters = {
    collections: (state) => state.collections,
};

const state = {
    collections: [
        // {
        //     id: 1,
        //     title: "Название чего нибудь",
        //     description:
        //         "Lorem ipsum dolor sit amet, consectetur adipisicing elit.\n" +
        //         "                    Architecto at consectetur culpa ducimus eum fuga fugiat,\n" +
        //         "                    ipsa iusto, modi nostrum recusandae reiciendis saepe",
        //     media: [
        //         {
        //             id: 1,
        //             type: "image",
        //             src: "https://placeimg.com/140/180/animals",
        //         },
        //         {
        //             id: 2,
        //             type: "image",
        //             src: "https://placeimg.com/640/480/tech",
        //         },
        //         {
        //             id: 3,
        //             type: "image",
        //             src: "https://placeimg.com/640/480/any",
        //         },
        //     ],
        // },
    ],
};

export const SET_COLLECTIONS = "setCollections";

const mutations = {
    [SET_COLLECTIONS](state, payload) {
        state.collections = payload;
    },
};

export const GET_COLLECTIONS = "getCollections";
export const GET_COLLECTION = "getCollection";

const actions = {
    [GET_COLLECTIONS](context) {
        return new Promise((resolve, reject) => {
            api.post("/collections.list")
                .then((r) => {
                    context.commit(SET_COLLECTIONS, r.data);
                    resolve(r.data);
                })
                .catch((r) => {
                    reject(r);
                });
        });
    },
    [GET_COLLECTION](context, id) {
        return new Promise((resolve, reject) => {
            api.post("/collections.getOne", { id })
                .then((r) => {
                    resolve(r.data);
                })
                .catch((r) => {
                    reject(r);
                });
        });
    },
};

export default {
    getters,
    state,
    mutations,
    actions,
};
